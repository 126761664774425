import { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import "./css/style.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import React, { useContext } from "react";
import { ThemeContext } from "./context/ThemeContext";
import AlertGlobal from "./jsx/components/AlertGlobal";
import AppProfile from "./jsx/components/AppsMenu/AppProfile/AppProfile";
import Home from "./jsx/components/Dashboard/Home";
import HomeDaLat from "./jsx/components/DashboardDaLat/Home";
import Footer from "./jsx/layouts/Footer";
import Nav from "./jsx/layouts/nav";
import TopBar from "./jsx/layouts/nav/TopBar";
import { selectorPermission } from "./store/slices/userPermission";
import axiosClient from "./api/axiosClient";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Version from "./jsx/layouts/Version";

const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(resolve, 500);
  }).then(() => import("./jsx/pages/Login"));
});
const SignUp = lazy(() => import("./jsx/pages/Registration"));

// Menu người dùng
const MenuUser = lazy(() => import("./jsx/pages/MenuUser"));
// Menu chức năng
const MenuFunction = lazy(() => import("./jsx/pages/MenuFunction"));
// Menu ngành nghề
const MenuIndustry = lazy(() => import("./jsx/pages/MenuIndustry"));
// Menu khu vực
const KhuVuc = lazy(() => import("./jsx/pages/KhuVuc"));
const ListSubArea = lazy(() => import("./jsx/pages/ListSubArea"));
//Menu Khách hàng
const KhachHang = lazy(() => import("./jsx/pages/KhachHang"));
const KhachHangBaoCao = lazy(() => import("./jsx/pages/KhachHangBaoCao"));
// Menu loại điểm kinh doanh (Lô)
const MatBang = lazy(() => import("./jsx/pages/MatBang"));
const MatBangBaoCao = lazy(() => import("./jsx/pages/MatBangBaoCao"));
const DetailAreaType = lazy(() => import("./jsx/pages/DetailAreaType"));
// Menu khu vực
const MenuLand = lazy(() => import("./jsx/pages/MenuLand"));
// Menu bảng giá
const MenuPriceList = lazy(() => import("./jsx/pages/MenuPriceList"));
// Menu bảng giá hợp đồng
const MenuContractPriceList = lazy(() => import("./jsx/pages/MenuContractPriceList"));
// Menu quyền truy cập
const MenuPermission = lazy(() => import("./jsx/pages/MenuPermission"));
// Menu cấu hình hệ thống
const MenuSetting = lazy(() => import("./jsx/pages/MenuSetting"));
const DetailPermission = lazy(() => import("./jsx/pages/DetailPermission"));
// Menu quản lý thu
const CustomerRegister = lazy(() => import("./jsx/pages/CustomerRegister"));
const CustomerRegisterBaoCao = lazy(() => import("./jsx/pages/CustomerRegisterBaoCao"));

const HoKinhDoanh = lazy(() => import("./jsx/pages/HoKinhDoanh"));
const HoKinhDoanhBaoCao = lazy(() => import("./jsx/pages/HoKinhDoanhBaoCao"));
const HoSoKhachHang = lazy(() => import("./jsx/pages/HoSoKhachHang"));
const HoSoKhachHangBaoCao = lazy(() => import("./jsx/pages/HoSoKhachHangBaoCao"));
// Các trang chức năng
const ChangePassword = lazy(() => import("./jsx/pages/ChangePassword"));
// Menu danh mục
const BanQuanLy = lazy(() => import("./jsx/pages/BanQuanLy"));
const GioiTinh = lazy(() => import("./jsx/pages/GioiTinh"));
const HienTrang = lazy(() => import("./jsx/pages/HienTrang"));
const HinhThucThanhToan = lazy(() => import("./jsx/pages/HinhThucThanhToan"));
const PhanLoaiHoaDon = lazy(() => import("./jsx/pages/PhanLoaiHoaDon"));
const SuDungDien = lazy(() => import("./jsx/pages/SuDungDien"));
const SuDungNuoc = lazy(() => import("./jsx/pages/SuDungNuoc"));
const TrangThaiDangKy = lazy(() => import("./jsx/pages/TrangThaiDangKy"));
const TrangThaiHoaDon = lazy(() => import("./jsx/pages/TrangThaiHoaDon"));
const TrangThaiSuDung = lazy(() => import("./jsx/pages/TrangThaiSuDung"));
const KieuHopDong = lazy(() => import("./jsx/pages/kieuHopDong"));
const LoaiDonVi = lazy(() => import("./jsx/pages/LoaiDonVi"));
const LoaiGiayTo = lazy(() => import("./jsx/pages/LoaiGiayTo"));
const DanhMucCHo = lazy(() => import("./jsx/pages/DanhMucCho"));
const LoaiHoaDon = lazy(() => import("./jsx/pages/LoaiHoaDon"));
const KhoanMucPhi = lazy(() => import("./jsx/pages/KhoanMucPhi"));
const LoaiHoSo = lazy(() => import("./jsx/pages/LoaiHoSo"));
const KhoHoSo = lazy(() => import("./jsx/pages/KhoHoSo"));
const danhSachTienHopDong = lazy(() => import("./jsx/pages/TienHopDong"));
const ChiTietBanQuanLy = lazy(() => import("./jsx/pages/ChiTietBanQuanLy"));
const ChiTietCho = lazy(() => import("./jsx/pages/ChiTietCho"));
const ChiTietKhoanMucPhi = lazy(() => import("./jsx/pages/ChiTietKhoanMucPhi"));
const ChiTietPhanLoaiHoaDon = lazy(
  () => import("./jsx/pages/ChiTietPhanLoaiHoaDon")
);
const ChiTietMatBang = lazy(() => import("./jsx/pages/ChiTietMatBang"));
const ChiTietMatBangBaoCao = lazy(() => import("./jsx/pages/ChiTietMatBangBaoCao"));
const NhomVe = lazy(() => import("./jsx/pages/NhomVe"));
const LoaiVe = lazy(() => import("./jsx/pages/LoaiVe"));
const TuyenThu = lazy(() => import("./jsx/pages/TuyenThu"));

// Menu Quản lý kế hoạch thu
const CapNhatDichVuSuDungTheoChiSo = lazy(
  () => import("./jsx/pages/CapNhatDichVuSuDungTheoChiSo")
);

// Menu Quản lý thu
const PhatSinhHoaDonLe = lazy(() => import("./jsx/pages/PhatSinhHoaDonLe"));
const PhatSinhTrongThang = lazy(() => import("./jsx/pages/PhatSinhTrongThang"));
const PhatSinhHangRong = lazy(() => import("./jsx/pages/PhatSinhHangRong"));
const ThuPhiDichVu = lazy(() => import("./jsx/pages/ThuPhiDichVu"));
const ThuNoDong = lazy(() => import("./jsx/pages/ThuNoDong"));
const ThuNoDongNgoaiKeHoach = lazy(() => import("./jsx/pages/ThuNoDongNgoaiKeHoach"));
const ThuDichVuChiSo = lazy(() => import("./jsx/pages/ThuDichVuChiSo"));
const DieuChinhKeHoachThu = lazy(
  () => import("./jsx/pages/DieuChinhKeHoachThu")
);
const LichSuDieuChinhKeHoachThu = lazy(
  () => import("./jsx/pages/LichSuDieuChinhKeHoachThu")
);
const ThuTraTruoc = lazy(() => import("./jsx/pages/ThuTraTruoc"));

const ThongTinKhachHang = lazy(() => import("./jsx/pages/ThongTinKhachHang"));
const ThongTinKhachHangBaoCao = lazy(() => import("./jsx/pages/ThongTinKhachHangBaoCao"));

// const ThuPhiHangRong = lazy(() => import("./jsx/pages/ThuPhiHangRong"));
const SoLieuSoBoTongHop = lazy(() => import("./jsx/pages/SoLieuSoBoTongHop"));
const SoLieuSoBoTongHopHaiChau = lazy(() => import("./jsx/pages/SoLieuSoBoTongHopHaiChau"));
const SoLieuSoBoChiTiet = lazy(() => import("./jsx/pages/SoLieuSoBoChiTiet"));
const XuatSoBo = lazy(() => import("./jsx/pages/XuatSoBo"));
const ThuTheoKeHoach = lazy(() => import("./jsx/pages/ThuTheoKeHoach"));
// Menu Quản lý đăng ký
const PheDuyetDangKyMoi = lazy(() => import("./jsx/pages/PheDuyetDangKyMoi"));
const PheDuyetNghiHan = lazy(() => import("./jsx/pages/PheDuyetNghiHan"));
const ChiTietPheDuyetKhachHang = lazy(
  () => import("./jsx/pages/ChiTietPheDuyetKhachHang")
);
// Menu Quản lý tuyến thu
const GanNguoiDungTuyenThu = lazy(
  () => import("./jsx/pages/GanNguoiDungTuyenThu")
);
const GanMatBangTuyenThu = lazy(() => import("./jsx/pages/GanMatBangTuyenThu"));
// Menu Báo cáo
const BaoCaoDanhSachHR = lazy(() => import("./jsx/pages/BaoCaoDanhSachHR"));
const BaoCaoDanhSachHRChiTiet = lazy(() => import("./jsx/pages/BaoCaoDanhSachHRChiTiet"));
const BaoCaoDanhSachThu = lazy(() => import("./jsx/pages/BaoCaoDanhSachThu"));
const BaoCaoDanhSachThu2 = lazy(() => import("./jsx/pages/BaoCaoDSThu2"));
const BaoCaoCongNo = lazy(() => import("./jsx/pages/BaoCaoCongNo"));
const MauBaoCao = lazy(() => import("./jsx/pages/MauBaoCao"));
const BaoCaoHDKDCacCho = lazy(() => import("./jsx/pages/BaoCaoHDKDCacCho"));
const BaoCaoSoBoTongHop = lazy(() => import("./jsx/pages/BaoCaoSoBoTongHop"));
const BaoCaoTuyenThu = lazy(() => import("./jsx/pages/BaoCaoTuyenThu"));
const BaoCaoSoBoChiTiet = lazy(() => import("./jsx/pages/BaoCaoSoBoChiTiet"));
const BaoCaoSoBoChiTietNam = lazy(() => import("./jsx/pages/BaoCaoSoBoChiTietNam"));
const SoBoChiTietKhuVuc = lazy(() => import("./jsx/pages/SoBoChiTietKhuVuc"));
const BaoCaoHieuSuatSuDungDTBH = lazy(
  () => import("./jsx/pages/BaoCaoHieuSuatSuDungDTBH")
);
const BaoCaoBienDongHKD = lazy(() => import("./jsx/pages/BaoCaoBienDongHKD"));
const BaoCaoNganhHangKinhDoanh = lazy(
  () => import("./jsx/pages/BaoCaoNganhHangKinhDoanh")
);
const BaoCaoDSThu = lazy(() => import("./jsx/pages/BaoCaoDSThu"));
const BaoCaoHDKD = lazy(() => import("./jsx/pages/BaoCaoHDKD"));
const BaoCaoSoBoChiTietKLKD = lazy(
  () => import("./jsx/pages/BaoCaoSoBoChiTietKLKD")
);
const BaoCaoThongKeVe = lazy(() => import("./jsx/pages/BaoCaoThongKeVe"));
const BaoCaoVeLe = lazy(() => import("./jsx/pages/BaoCaoVeLe"));
const BaoCaoSoBoGoc = lazy(() => import("./jsx/pages/BaoCaoSoBoGoc"));
const BaoCaoSoBoChiTietThuHo = lazy(() => import("./jsx/pages/BaoCaoSoBoChiTietThuHo"));
const BaoCaoCongNoChiTiet = lazy(() => import("./jsx/pages/BaoCaoCongNoChiTiet"));
const BaoCaoHoaDonThuThangKeHoach = lazy(() => import("./jsx/pages/BaoCaoHoaDonThuThangKeHoach"));
const BaoCaoTinhHinhSDBL = lazy(() => import("./jsx/pages/BaoCaoTinhHinhSDBL"));
const BaoCaoDSThuTongHop = lazy(() => import("./jsx/pages/BaoCaoDSThuTongHop"));
const BaoCaoDieuChinh = lazy(() => import("./jsx/pages/BaoCaoDieuChinh"));
const BaoCaoHoKinhDoanhTongHop = lazy(() => import("./jsx/pages/BaoCaoHoKinhDoanhTongHop"));
const BaoCaoTienHopDong = lazy(() => import("./jsx/pages/BaoCaoTienHopDong"));

const BienLaiThuTien = lazy(() => import("./jsx/pages/BienLaiThuTien"));


// Hóa đơn điện tử
const HoaDonDienTu = lazy(() => import("./jsx/pages/HoaDonDienTu"));
const DanhSachHoaDon = lazy(() => import("./jsx/pages/DanhSachHoaDon"));
const DanhSachHoaDonViettel = lazy(() => import("./jsx/pages/DanhSachHoaDonViettel"));
const DanhSachThuHaiChau = lazy(() => import("./jsx/pages/DanhSachThuHaiChau"));
const ChiTietDieuChinhKeHoach = lazy(
  () => import("./jsx/pages/ChiTietDieuChinhKeHoach")
);
// Vé điện tử
const VeDienTu = lazy(() => import("./jsx/pages/VeDienTu"));
const HoaDonKhac = lazy(() => import("./jsx/pages/HoaDonKhac"));
const VeGuiXeMay = lazy(() => import("./jsx/pages/VeGuiXeMay"));

// Menu quản lý hồ sơ
const QuanLyHoSo = lazy(() => import("./jsx/pages/QuanLyHoSo"));

// Ứng dụng máy POS
const UngDungMayPOS = lazy(() => import("./jsx/pages/UngDungMayPOS"));
const ThongBao = lazy(() => import("./jsx/pages/ThongBao"));
const ThongBaoPhieuNo = lazy(() => import("./jsx/pages/ThongBaoPhieuNo"));
//Kiểm tra hết hạn
const ExpiredCheck = lazy(() => import("./jsx/pages/HetHanHopDong"));
//Thong Tin Hop Dong
const DanhSachHopDong = lazy(() => import("./jsx/pages/DanhSachHopDong"));
//Menu Quản Lý Nợ
const QuanLyNo = lazy(()=>import("./jsx/pages/QuanLyNo"));
const ThuTraGop = lazy(()=>import("./jsx/pages/ThuTraGop"));
//Menu Điện hao hụt
const DienHaoHut = lazy(()=>import("./jsx/pages/DienHaoHut"));
const LoaiDienHaoHut = lazy(()=>import("./jsx/pages/LoaiDienHaoHut"));
const GanMatBangDienHaoHut = lazy(()=>import("./jsx/pages/GanMatBangDienHaoHut"));
const ThanhToan = lazy(() => import("./jsx/pages/ThanhToan"));
const DanhSachTienHopDong = lazy(() => import("./jsx/pages/DanhSachTienHopDong"));
const DanhMucZNSTemplate = lazy(() => import("./jsx/pages/DanhMucZNSTemplate"));
const DanhMucDinhDangDuLieuZNS = lazy(() => import("./jsx/pages/DanhMucDinhDangDuLieuZNS"));
const PhatHanhTinNhanZalo = lazy(() => import("./jsx/pages/PhatHanhTinNhanZalo"));
const PhatHanhThongBaoIn = lazy(() => import("./jsx/pages/PhatHanhThongBaoIn"));
const App: React.FC = () => {
  const menuToggle = useContext(ThemeContext);
  const dataMenu = useSelector(selectorPermission);
  const { permission } = dataMenu;

  const [mainTitle, setMainTitle] = useState("");
  const [, setTitleIndex] = useState("");
  const [, setTitleLogin] = useState("");
  const [, setTitleLoginSpan] = useState("");

  let path: any = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  let location = useLocation();

  useEffect(() => {
    var url = window.location.pathname.split("/")[1];
    if (
      url.search("dang-ky-khach-hang-by-id") > -1 ||
      url.search("thu-phi-hang-rong-by-id") > -1
    ) {
      return;
    }
    if (
      window.location.pathname !== "/" &&
      window.location.pathname.search("/thanh-toan") &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/trang-ca-nhan" &&
      window.location.pathname !== "/doi-mat-khau" &&
      window.location.pathname.search("chi-tiet") < 0
    ) {
      if (!permission.includes(`/${url}`)) {
        alert("Bạn không có quyền truy cập vào chức năng này");
        window.history.back();
      }
    }
    axiosClient.defaults.headers['current-page'] = window.location.pathname;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const fechMarketInfo = async () => {
      const response = await axiosClient.get("cau-hinh-cho");
      setMainTitle(response.data.main_title ?? "");
      setTitleIndex(response.data.title_index ?? "");
      setTitleLogin(response.data.title_login ?? "");
      setTitleLoginSpan(response.data.title_login_span ?? "");
      //console.log("mainTitle", mainTitle);
    };
    fechMarketInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let routes = (
    <HelmetProvider>
      <Helmet>
        <title>{mainTitle ?? "Quản lý chợ"}</title>
        {/* <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" /> */}
      </Helmet>
      <TopBar />
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "" : "menu-toggle"
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid pb-5" : ""}`}
          // style={{ minHeight: window.screen.height - 60 }}
          >
            <Route path="/trang-chu" component={HomeDaLat} />
            {/* <Route path="/trang-chu-da-lat" component={HomeDaLat} /> */}
            <Route path="/nguoi-dung" component={MenuUser} />
            {/* <Route path='/chi-tiet-nguoi-dung' component={AppProfile} /> */}
            <Route
              path="/chi-tiet-nguoi-dung/:idNguoiDung"
              component={AppProfile}
            />
            <Route path="/chuc-nang" component={MenuFunction} />
            <Route path="/nganh-hang" component={MenuIndustry} />
            <Route path="/khu-vuc" component={KhuVuc} />
            <Route path="/loai-mat-bang" component={MenuLand} />
            <Route path="/mat-bang-lo" component={MatBang} />
            <Route path="/mat-bang-lo-bao-cao" component={MatBangBaoCao} />
            <Route path="/bang-gia" component={MenuPriceList} />
            {/*Tien Hop Dong */}
            <Route path="/tien-hop-dong" component={MenuContractPriceList} />
            <Route path="/khach-hang" component={KhachHang} />
            <Route path="/khach-hang-bao-cao" component={KhachHangBaoCao} />
            <Route path="/quan-ly-tien-hop-dong" component={danhSachTienHopDong} />
            <Route path="/quyen-truy-cap" component={MenuPermission} />
            <Route path="/cau-hinh-he-thong" component={MenuSetting} />
            <Route path="/trang-ca-nhan" component={AppProfile} />
            <Route path="/chi-tiet-khu-vuc/:idKhuVuc" component={ListSubArea} />
            <Route
              path="/chi-tiet-quyen-truy-cap/:idQuyen"
              component={DetailPermission}
            />
            <Route path="/doi-mat-khau" component={ChangePassword} />
            <Route path="/chi-tiet-lo/:idMatBang" component={DetailAreaType} />
            <Route path="/ban-quan-ly" component={BanQuanLy} />
            <Route path="/gioi-tinh" component={GioiTinh} />
            <Route path="/hien-trang" component={HienTrang} />
            <Route path="/hinh-thuc-thanh-toan" component={HinhThucThanhToan} />
            <Route path="/phan-loai-hoa-don" component={PhanLoaiHoaDon} />
            <Route path="/loai-hoa-don" component={LoaiHoaDon} />
            <Route path="/su-dung-dien" component={SuDungDien} />
            <Route path="/su-dung-nuoc" component={SuDungNuoc} />
            <Route path="/trang-thai-dang-ky" component={TrangThaiDangKy} />
            <Route path="/trang-thai-hoa-don" component={TrangThaiHoaDon} />
            <Route path="/trang-thai-su-dung" component={TrangThaiSuDung} />
            <Route path="/kieu-hop-dong" component={KieuHopDong} />
            <Route path="/loai-don-vi" component={LoaiDonVi} />
            <Route path="/loai-giay-to" component={LoaiGiayTo} />
            <Route path="/danh-muc-cho" component={DanhMucCHo} />
            <Route path="/khoan-muc-phi" component={KhoanMucPhi} />
            <Route path="/loai-ho-so" component={LoaiHoSo} />
            <Route path="/kho-ho-so" component={KhoHoSo} />
            <Route path="/nhom-ve" component={NhomVe} />
            <Route path="/loai-ve" component={LoaiVe} />

            {/* Menu Quản lý kế hoạch thu */}
            <Route
              path="/cap-nhat-dich-vu-su-dung-theo-chi-so"
              component={CapNhatDichVuSuDungTheoChiSo}
            />

            {/* Menu Quản lý HKD */}
            <Route path="/dang-ky-khach-hang" component={CustomerRegister} />
            <Route path="/dang-ky-khach-hang-bao-cao" component={CustomerRegisterBaoCao} />
            <Route path="/ho-kinh-doanh" component={HoKinhDoanh} />
            <Route path="/ho-kinh-doanh-bao-cao" component={HoKinhDoanhBaoCao} />
            <Route path="/ho-so-khach-hang" component={HoSoKhachHang} />
            <Route path="/ho-so-khach-hang-bao-cao" component={HoSoKhachHangBaoCao} />
            <Route path="/het-han-hop-dong" component={ExpiredCheck} />
            <Route path="/danh-sach-hop-dong" component={DanhSachHopDong}/>
            <Route
              path="/chi-tiet-bang-quan-ly/:idBangQuanLy"
              component={ChiTietBanQuanLy}
            />
            <Route path="/chi-tiet-cho/:idCho" component={ChiTietCho} />
            <Route
              path="/chi-tiet-Khoan-muc-phi/:idKhoanMucPhi"
              component={ChiTietKhoanMucPhi}
            />
            <Route
              path="/chi-tiet-phan-loai-hoa-don/:idPhanLoaiHoaDon"
              component={ChiTietPhanLoaiHoaDon}
            />
            <Route
              path="/chi-tiet-mat-bang/:idMatBang"
              component={ChiTietMatBang}
            />
            <Route
                path="/chi-tiet-mat-bang-bao-cao/:idMatBang"
                component={ChiTietMatBangBaoCao}
            />
            {/* Menu Quản lý thu */}
            <Route
              path="/phat-sinh-trong-thang"
              component={PhatSinhTrongThang}
            />
            <Route path="/phat-sinh-hoa-don-le" component={PhatSinhHoaDonLe} />
            <Route path="/thu-phi-hang-rong" component={PhatSinhHangRong} />
            <Route path="/thu-phi-dich-vu" component={ThuPhiDichVu} />
            <Route path="/thu-no-dong" component={ThuNoDong} />
            <Route path="/thu-no-dong-ngoai-ke-hoach" component={ThuNoDongNgoaiKeHoach} />

            <Route path="/thu-dich-vu-chi-so" component={ThuDichVuChiSo} />
            {/*Quản lý nợ*/}
            <Route path="/quan-ly-no" component={QuanLyNo}/>
            <Route
              path="/danh-sach-dieu-chinh"
              component={DieuChinhKeHoachThu}
            />
            <Route
              path="/lich-su-dieu-chinh-ke-hoach-thu"
              component={LichSuDieuChinhKeHoachThu}
            />
            <Route
              path="/chi-tiet-dieu-chinh-ke-hoach-thu/:IdDieuChinh/:IdKhoanMucPhi/:IdCho"
              component={ChiTietDieuChinhKeHoach}
            />
            <Route
              path="/thong-tin-chi-tiet-khach-hang/:idKhachHang"
              component={ThongTinKhachHang}
            />
            <Route
                path="/thong-tin-chi-tiet-khach-hang-bao-cao/:idKhachHang"
                component={ThongTinKhachHangBaoCao}
            />
            <Route path="/thu-tra-truoc" component={ThuTraTruoc} />

            {/* <Route path='/thu-phi-hang-rong1' component={ThuPhiHangRong} /> */}
            <Route
              path="/so-lieu-so-bo-tong-hop"
              component={SoLieuSoBoTongHop}
            />
            <Route
              path="/so-bo-tong-hop-hai-chau"
              component={SoLieuSoBoTongHopHaiChau}
            />
            <Route
              path="/so-lieu-so-bo-chi-tiet"
              component={SoLieuSoBoChiTiet}
            />
            <Route 
              path="/so-lieu-so-bo-goc"
              component={BaoCaoSoBoGoc}
            />
            <Route path="/xuat-so-bo" component={XuatSoBo} />
            <Route path="/thu-theo-ke-hoach" component={ThuTheoKeHoach} />

            <Route
              path="/dang-ky-khach-hang-by-id/:id"
              component={CustomerRegister}
            />
            <Route
              path="/thu-phi-hang-rong-by-id/:idKhachHang/:idSuDungMatBang"
              component={PhatSinhHangRong}
            />
            {/* Menu Quản lý đăng ký */}
            <Route
              path="/phe-duyet-dang-ky-moi"
              component={PheDuyetDangKyMoi}
            />
            <Route path="/phe-duyet-nghi-han" component={PheDuyetNghiHan} />
            <Route
              path="/chi-tiet-phe-duyet/:idKhachHangTam"
              component={ChiTietPheDuyetKhachHang}
            />
            {/* Menu Báo cáo */}
            <Route path="/bao-cao-thu-hang-rong" component={BaoCaoDanhSachHR} />
            <Route path="/bao-cao-thu-hang-rong-chi-tiet" component={BaoCaoDanhSachHRChiTiet} />
            <Route
              path="/bao-cao-danh-sach-thu"
              component={BaoCaoDanhSachThu}
            />
            <Route
              path="/bao-cao-danh-sach-thu-2"
              component={BaoCaoDanhSachThu2}
            />
            <Route path="/bao-cao-cong-no" component={BaoCaoCongNo} />
            <Route path="/mau-bao-cao" component={MauBaoCao} />
            <Route path="/bao-cao-hdkd-cac-cho" component={BaoCaoHDKDCacCho} />
            <Route
              path="/bao-cao-so-bo-tong-hop"
              component={BaoCaoSoBoTongHop}
            />
            <Route
               path={"/so-bo-khu-vuc"}
               component={SoBoChiTietKhuVuc}
             />
            <Route
              path="/bao-cao-tuyen-thu"
              component={BaoCaoTuyenThu}
            />
            <Route
              path="/bao-cao-so-bo-chi-tiet"
              component={BaoCaoSoBoChiTiet}
            />
            <Route
              path="/bao-cao-so-bo-chi-tiet-nam"
              component={BaoCaoSoBoChiTietNam}
            />
            <Route
              path="/bao-cao-so-bo-chi-tiet-thu-ho"
              component={BaoCaoSoBoChiTietThuHo}
            />
            <Route
            path="/bao-cao-hieu-suat-su-dung-dtbh"
            component={BaoCaoHieuSuatSuDungDTBH}
          />
          <Route
            path="/bao-cao-nganh-hang-kinh-doanh"
            component={BaoCaoNganhHangKinhDoanh}
          />
          <Route
            path="/bao-cao-bien-dong-ho-kinh-doanh"
            component={BaoCaoBienDongHKD}
          />
            <Route path="/bao-cao-ds-thu" component={BaoCaoDSThu} />
            <Route path="/bao-cao-hdkd" component={BaoCaoHDKD} />
            <Route
              path="/bao-cao-bien-dong-hkd"
              component={BaoCaoSoBoChiTietKLKD}
            />
            
            <Route path="/bao-cao-ds-thu-tong-hop" component={BaoCaoDSThuTongHop} />
            <Route path="/bao-cao-thong-ke-ve" component={BaoCaoThongKeVe} />
            <Route path="/bao-cao-ve-le" component={BaoCaoVeLe} />
            <Route path="/bao-cao-cong-no-chi-tiet" component={BaoCaoCongNoChiTiet} />
            <Route path="/bao-cao-hoa-don-thu-thang" component={BaoCaoHoaDonThuThangKeHoach}/>
            <Route path="/bao-cao-tinh-hinh-su-dung-bien-lai" component={BaoCaoTinhHinhSDBL}/>
            <Route path="/bao-cao-dieu-chinh" component={BaoCaoDieuChinh} />
            <Route path="/bao-cao-ho-kinh-doanh-tong-hop" component={BaoCaoHoKinhDoanhTongHop} />
            <Route path="/bao-cao-tien-hop-dong" component={BaoCaoTienHopDong} />
            
            <Route path="/bien-lai-thu-tien" component={BienLaiThuTien} />
            <Route path="/tuyen-thu" component={TuyenThu} />
            {/* Hóa đơn điện tử */}
            <Route path="/hoa-don-dien-tu" component={HoaDonDienTu} />
            <Route path="/danh-sach-hoa-don" component={DanhSachHoaDon} />
            <Route path="/danh-sach-hoa-don-viettel" component={DanhSachHoaDonViettel} />
            <Route path="/danh-sach-thu-hai-chau" component={DanhSachThuHaiChau} />
            {/* Vé điện tử */}
            <Route path="/ve-dien-tu" component={VeDienTu} />
            <Route path="/ve-gui-xe-may" component={VeGuiXeMay} />
            <Route path="/hoa-don-khac" component={HoaDonKhac} />
            {/* Ứng dụng máy POS */}
            <Route path="/ung-dung-may-pos" component={UngDungMayPOS} />
            <Route path="/thong-bao-tien-dien" component={ThongBao} />
            <Route path="/thong-bao-phieu-no" component={ThongBaoPhieuNo}/>
            {/* Tuyến thu */}
            <Route
              path="/gan-nguoi-dung-tuyen-thu"
              component={GanNguoiDungTuyenThu}
            />
            <Route
              path="/gan-mat-bang-tuyen-thu"
              component={GanMatBangTuyenThu}
            />
            {/* Quản lý hồ sơ */}
            <Route path="/quan-ly-ho-so" component={QuanLyHoSo} />

            {/*Thu Trả Góp*/}
            <Route path="/thu-tra-gop" component={ThuTraGop} />
            {/*Điện hao hụt*/}
            <Route path="/dien-hao-hut" component={DienHaoHut} />
            <Route path="/loai-dien-hao-hut" component={LoaiDienHaoHut} />
            <Route path="/gan-mat-bang-dien-hao-hut" component={GanMatBangDienHaoHut}/>
            <Route path="/danh-sach-tien-hop-dong" component={DanhSachTienHopDong}/>
            <Route path="/danh-muc-zns-template" component={DanhMucZNSTemplate}/>
            <Route path="/danh-muc-dinh-dang-du-lieu" component={DanhMucDinhDangDuLieuZNS}/>
            <Route path="/phat-hanh-tin-nhan" component={PhatHanhTinNhanZalo}/>
            <Route path="/phat-hanh-thong-bao-in" component={PhatHanhThongBaoIn}/>
          </div>
        </div>
        {!pagePath && <Footer />}
        {!pagePath && <Version/>}
      </div>
    </HelmetProvider>
  );

  return (
    // <div className="vh-100">
    <div>
      <AlertGlobal />
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Switch>
          {/* {routesNoHeaderFooter} */}
          <Route path="/login" component={Login} />
          <Route exact path="/" component={Login} />
          <Route path="/page-register" component={SignUp} />
          <Route path="/thanh-toan/:id" component={ThanhToan} />
          {routes}
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
