import { paserParams } from "../until";
import axiosClient, { ResponseSuccess } from "./axiosClient";

//6

export interface DanhSachLichSuGiaoDich {
  details: Details[];
}

export interface Details {
  idCho: string;
  tenCho: string;
  thuNo: number;
  tongNo: number;
  thuKeHoach: number;
  thuNoDong: number;
  thuPhatSinh: number;
  thuPhiHangRong: number;
}
export interface DanhSachChiSo {
  hoaDonXuatThanhCong: Number;
  hoaDonXuatThanhCongHDDT: Number;
  tongTienThu: Number;
  veBanRa: Number;
  tongTienVeBanRa: Number;
  hoaDonPhatHanhLoi: Number;
}

export interface DanhSachChiSoV2 {
  hoaDonPhatHanh: Number;
  soHoDangKinhDoanh: Number;
  soHoDaThu: Number;
  tongTienPhaiThu: Number;
  tongTienDaThu: Number;
  tongDoanhThu: Number;
}
export const layDanhSachLichSuGiaoDich = async (
  Loai: string,
  TuNgay: string = "",
  DenNgay: string = "",
  ThangNam: string = ""
) => {
  const path = "trang-chu/thong-ke-so-lieu";
  const params = {
    Loai,
    TuNgay,
    DenNgay,
    ThangNam,
  };
  const result: ResponseSuccess<DanhSachLichSuGiaoDich> = await axiosClient.get(
    path,
    { params: paserParams(params, "") }
  );
  return result;
};

export const layDanhSachChiSo = async (
  Loai: string,
  TuNgay: string = "",
  DenNgay: string = "",
  ThangNam: string = ""
) => {
  const path = "trang-chu/thong-ke-chi-so";
  const params = {
    Loai,
    TuNgay,
    DenNgay,
    ThangNam,
  };
  const result: ResponseSuccess<DanhSachChiSo> = await axiosClient.get(path, {
    params: paserParams(params, ""),
  });
  return result;
};

export const layDanhSachChiSoV2 = async (
  Loai: string,
  TuNgay: string = "",
  DenNgay: string = "",
  ThangNam: string = ""
) => {
  const path = "trang-chu/thong-ke-chi-so-v2";
  const params = {
    Loai,
    TuNgay,
    DenNgay,
    ThangNam,
  };
  const result: ResponseSuccess<DanhSachChiSoV2> = await axiosClient.get(path, {
    params: paserParams(params, ""),
  });
  return result;
};
