import { VERSION } from '../../env';
const Version = () => {
  return (
    <>
      <div
        className="position-fixed end-0 bottom-0 pb-2 "
        style={{ zIndex: 1 }}
      >
          Version {VERSION}
      </div>
    </>
  );
};

export default Version;